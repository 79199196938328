import React from 'react';
import { Layout, Seo } from '@components/core';
import {
	LandingLayout,
	LandingBackground,
	LandingHeading,
	LandingBottom,
} from '@components/ui/landing';

const IndexPage = () => (
	<Layout isOverflowed={true}>
		<Seo />
		<LandingLayout>
			<LandingBackground />
			<LandingHeading />
			<LandingBottom />
		</LandingLayout>
	</Layout>
);

export default IndexPage;
